
import api from './api'



class Ecommerce {


    async GetProductListhome(isMarketPlace, IsFeateured, companyId, serachValue) {
        return await api.get(`HomeFront/GetProductListingFront/${isMarketPlace}/${IsFeateured}/${companyId}/${serachValue}`);
    }
    async GetProductListhome(isMarketPlace, IsFeateured, companyId) {
        return await api.get(`HomeFront/GetProductListingFront/${isMarketPlace}/${IsFeateured}/${companyId}`);
    }
    async GetRecommededProductListingFrontHome(isMarketPlace, IsFeateured, companyId) {
        return await api.get(`HomeFront/GetRecommededProductListingFront/${isMarketPlace}/${IsFeateured}/${companyId}`);
    }
    async GetSaverTodayProductListingHome(isMarketPlace, IsFeateured, companyId) {
        return await api.get(`HomeFront/GetSaverTodayProductListing/${isMarketPlace}/${IsFeateured}/${companyId}`);
    }
    async GetProductCategoryListFront(isMarketPlace, IsFeateured, companyId) {
        return await api.get(`HomeFront/GetProductCategoryListFront/${isMarketPlace}/${IsFeateured}/${companyId}`);
    }
    async GetProductListData(listFilter,pageSize, pageIndex, sortBy, OrderBy, CategoryID, CustomerLoginUserId, CurrentCartId, isMarketPlace) {
        return await api.get(`HomeFront/Shop/${listFilter}/${CategoryID}/${CustomerLoginUserId}/${CurrentCartId}/${pageSize}/${pageIndex}/${sortBy}/${OrderBy}/${isMarketPlace}`);
    }
    //async GetCartListData(Id, removePromo, CustomerLoginUserId, CurrentCartId) {
    //    debugger;
    //    if (CurrentCartId == null) {
    //        CurrentCartId = "00Test"
    //    }

    //    return await api.get(`FrontendShop/AddToCart/${Id}/${removePromo}/${CustomerLoginUserId}/${CurrentCartId}`);
    //}

    async GetCartListData(Id, removePromo, actionValue, CustomerLoginUserId, CurrentCartId) {
        if (CurrentCartId == null) {
            CurrentCartId = "00Test"
        }

        return await api.get(`FrontendShop/DynamicAddToCart/${Id}/${removePromo}/${actionValue}/${CustomerLoginUserId}/${CurrentCartId}/${false}`);
    }
    async RemovePromoCode(CurrentCartId, CustomerLoginUserId) {
        if (CustomerLoginUserId == null || CustomerLoginUserId == '' || CustomerLoginUserId == 'null') {
            CustomerLoginUserId = '00Test';
        }
        if (CurrentCartId == null || CurrentCartId == '' || CurrentCartId == 'null') {
            CurrentCartId = '00Test';
        }
        return await api.get(`FrontendShop/RemovePromoCode/${CurrentCartId}/${CustomerLoginUserId}`);
    }

    async GetProductDetailsFrontEndShop(productId, customerLoginId, currentCartId) {
        if (customerLoginId == null || customerLoginId == '' || customerLoginId == 'null') {
            customerLoginId = '00Test';
        }
        if (currentCartId == null || currentCartId == '' || currentCartId == 'null') {
            currentCartId = '00Test';
        }
       return await api.get( `FrontendShop/GetProductDetailsFrontEnd/${productId}/${customerLoginId}/${currentCartId}`);
    }
    async AddCouponCode(couponCode, CurrentCartId, CustomerLoginUserId) {
        return await api.get(`FrontendShop/couponCode/${couponCode}/${CurrentCartId}/${CustomerLoginUserId}`);
    }
  async  RefreshCartItems(cartId, itemId, CurrentCartId, loginuserId) {
        return await api.get(`FrontendShop/RefreshCartItems/${cartId}/${itemId}/${CurrentCartId}/${loginuserId}`);
    }
    async getorderdetail(orderid, userid, ordernumber) {

        return await api.get(`customerdashboard/${orderid}/${userid}/${ordernumber}`);

    }
    async SubmitCartDAta(data) {
        if (data.checkBox == false) {
            return await api.post(`FrontendShop/submitOrderData`, data);
        }
        else {
            return await api.post(`FrontendShop/submitOrderCashondelveryData`, data);
        }
       // return await api.post(`FrontendShop/submitOrderData`, data);
    }
    async UpdateQuantity(cartId, itemId, qty, CurrentCartId, loginuserId) {
        return await api.get(`FrontendShop/UpdateQuantity/${cartId}/${itemId}/${qty}/${CurrentCartId}/${loginuserId}`);
    }
    async DeleteCartItem(PageID, productId, CurrentCartId, loginuserId) {
        if (PageID == null || PageID == '' || PageID == 'null') {
            PageID = 'null';
        }
        if (loginuserId == null || loginuserId == '' || loginuserId == 'null') {
            loginuserId = 'null';
        }
        if (CurrentCartId == null || CurrentCartId == '' || CurrentCartId == 'null') {
            CurrentCartId = 'null';
        }
        return await api.get( `FrontendShop/DeleteCartItem/${PageID}/${productId}/${CurrentCartId}/${loginuserId}`);
    }
    async getfeatureddetails(isMarketPlace, IsFeateured, companyId) {
        return await api.get(`HomeFront/GetFeaturedProductListingFront/${isMarketPlace}/${IsFeateured}/${companyId}`)
    }
    async GetProductListhomeHeader(isMarketPlace, IsFeateured, companyId, serachValue) {
        return await api.get(`HomeFront/GetProductListingFront/${isMarketPlace}/${IsFeateured}/${companyId}/${serachValue}`);
    }

    async GetProductListingFront_Home(isMarketPlace, IsFeateured, companyId, serachValue) {
        return await api.get(`HomeFront/GetProductListingFront_Home/${isMarketPlace}/${IsFeateured}/${companyId}/${serachValue}`);
    }
    forgotpassword(forgotmodel) {
        return api.post('Account/ForgotPasswordForCompWeb', forgotmodel);
    }
   
    async signupmethods(model) {
        return await api.post(`Customer/RegisterCustomer`, model);
    }
    async GetIPAddress() {
        const response = await fetch('https://api.ipify.org?format=json');
        const data_res = await response.json();
        if (data_res != null) {
            return await data_res.ip;
        }
        else {
            return await "::1";
        }

    }
    async getStateList() {
        return await api.get('Customer/GetStateList');
    }
    async countryList(masterName, masterKeyText, uid) {
        debugger;
        return await api.get(`/Customer/GetMasterItems/${masterName}/${masterKeyText}/${uid}`);
    }
    async getCurrentUserDetail(userId, actionType) {
        let optionaParamersType = "00Test";
        let info;
        var TimeZone;
        debugger;
        await api.get(`UserProfile/GetUserInfo/${userId}/${actionType}/${optionaParamersType}`).then(response => (
            info = response,
            console.log("getCurrentUserDetail", info.data),
            useloggedUserInfo().setUser(info.data),

            localStorage.removeItem('userinfo'),
            localStorage.removeItem("dashboardItems"),
            localStorage.setItem("dashboardItems", "True"),
            localStorage.setItem('userinfo', JSON.stringify(response)),
            localStorage.removeItem('companyId'),

            localStorage.setItem("companyId", response.data.companyId),

            localStorage.removeItem('SettingObserver'),
            localStorage.setItem("SettingObserver", response.observerSettingName),
            localStorage.removeItem('SettingEmployee'),
            localStorage.setItem("SettingEmployee", response.employeeSettingName),
            localStorage.removeItem('TimeZone'),
            TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone, //response.timeZone;
            localStorage.setItem("TimeZone", TimeZone),

            localStorage.setItem('sliderMenu', JSON.stringify(1)),
            localStorage.setItem("usersucess", "yes"),

            localStorage.removeItem('primaryLogo'),
            localStorage.removeItem('secondaryLogo'),
            localStorage.removeItem('companyName'),

            localStorage.setItem("primaryLogo", response.data.primaryLogo),
            localStorage.setItem("secondaryLogo", response.data.secondaryLogo),
            localStorage.setItem("companyName", response.data.companyName),
            localStorage.setItem("userType", response.data.userType)
        ))
    }
    //   getUserModulePermissions(userId, actionType) {
    //       debugger;
    //       if (actionType == '' || actionType == null || typeof actionType == undefined) {
    //           actionType = '00Test';
    //       }
    //       if (localStorage.getItem("actionType") == 'AsAObserver') {
    //           actionType = localStorage.getItem("actionType");
    //       }
    ///*       return api.get(`common/GetUserModulePermissionList/${userId}`);*/
    //   }
    async getUserDetail(id, optional) {
        debugger;
        return await api.get(`UserProfile/GetUserDetailById/${id}/${optional}`);
    }
    async setPassword(setPassword) {
        debugger;
        return await api.post(`Account/ResetPassword`, setPassword);
    }
    async gettoken(email) {
        return await api.get(`UserProfile/gettoken/${email}`);
    }
    async updateUserProfile(userProfile) {
        debugger;
        return await api.post(`UserProfile/CompanyWebUpdateUser`, userProfile);
    }
    async getMasterForCustomer(masterNames, masterKeyText, uid) {
        return await api.get(`Customer/GetMasterItems/${masterNames}/${masterKeyText}/${uid}`)

    }
    async getCheckOutDataByCustomer(CustomerLoginUserId, CurrentCartId, AddressTyype, companyId) {

        if (CustomerLoginUserId == null || CustomerLoginUserId == '' || CustomerLoginUserId == 'null') {
            CustomerLoginUserId = 'null';
        }
        if (CurrentCartId == null || CurrentCartId == '' || CurrentCartId == 'null') {
            CurrentCartId = 'null';
        }
        return await api.get(`FrontendShop/CheckOut/${CustomerLoginUserId}/${CurrentCartId}/${AddressTyype}/${companyId}`);
    }
    async ChangeShipingMethods(shipingMethod, CurrentCartId, CustomerLoginUserId, shippingCharge, taxonShipping) {

        if (CustomerLoginUserId == null || CustomerLoginUserId == '' || CustomerLoginUserId == 'null') {
            CustomerLoginUserId = '00Test';
        }
        if (CurrentCartId == null || CurrentCartId == '' || CurrentCartId == 'null') {
            CurrentCartId = '00Test';
        }
        return await api.get(`FrontendShop/ChangeShipingMethods/${shipingMethod}/${CurrentCartId}/${CustomerLoginUserId}/${shippingCharge}/ ${taxonShipping }` );
    }
    async getShippingMethodList(searchFields, statusvalue, sortColumn, sortDir, pageIndex, pageSize, userid) {
        debugger;
        if (searchFields == '') { searchFields = '00Test' }
        return await api.get(`Shipping/${searchFields}/${statusvalue}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userid}`);
    }
    async GetOrdersReportList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId) {
        debugger;
        return await api.get(`Orders/GetCustomerOrdersList/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}`);
    }
    async getOrderDetails(orderId) {
        return await api.get(`Orders/GetOrderDetails/${orderId}`)
    }
    async GetTrainingList(Title, statusValue, pageIndex, pageSize, sortBy, orderBy, userId, TrainingFor, companyId, categoryId, location) {
        debugger;
        return await api.get(`Training/GetTrainingListForUser/${Title}/${statusValue}/${pageIndex}/${pageSize}/${sortBy}/${orderBy}/${userId}/${TrainingFor}/${companyId}/${categoryId}/${location}`)
    }
    async DownloadSingleFile(docname) {
        debugger;
        return await api.get(`Training/DownloadDoc/${docname}`, { responseType: 'blob' })
    }
    async AddUpdateReview(reviewmodel) {
        debugger;

        return api.post('ManageAsset/AddReview', reviewmodel);
    }
    async AddUpdatemarkectReview(reviewmodel) {
        debugger;

        return api.post('ManageAsset/addmarkectplacereview', reviewmodel);
    }

    async GetReviewsListing(userId, companyId, flag, pageIndex, pageSize, id) {

        return await api.get(`ManageAsset/GetReviewsListing/${userId}/${companyId}/${flag}/${pageIndex}/${pageSize}/${id}`);
    }
    async GetDynamicContentForComapny(companyId) {
        debugger;
        return await api.get(`ManageCompanyWebPage/GetDynamicContentForComapny/${companyId}`);
    }
    async GetSubscriptionOrdersList(searchFields, sortDir, sortColumn, pageIndex, pageSize, userId, companyId) {
        debugger;
        return await api.get(`Orders/GetSubscriptionOrdersList/${searchFields}/${sortDir}/${sortColumn}/${pageIndex}/${pageSize}/${userId}/${companyId}`);
    }
    async UpdateSubscriptionStatus(OrderId, statusValue) {
        debugger;
        return await api.post(`Orders/UpdateSubscriptionStatus/${OrderId}/${statusValue}`)
    }

    async DeleteCompanyUserAccount(tablename, id, title, userId, companyId, ipaddress) {
        return await api.get(`CompanyUsers/deleteaccount/${tablename}/${id}/${title}/${userId}/${companyId}/${ipaddress}`);
    }
    async GetShippingDetailListing(companyId) {
        return await api.get(`Orders/GetShippingDetailListing/${companyId}`)
    }
}


export default new Ecommerce();