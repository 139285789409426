import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../Layout/layout.vue'
import frontEndService from '../service/FrontendService.js'
import AuthUserServices from '@/service/AuthUserServices.js';
import HomeView from '../views/HomeView.vue'
/*import helpers from '../helpers/filters'*/

const routes = [
    {        
        path: '',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: '',
                name: 'HomePageDefault',
                redirect: to => {
                    return { path: `/Home` }
                }
            },
            {                
                path: '/Home',
                name: 'Home',
                component: () => import('@/views/HomeView.vue')
            },
            {
                path: '/Homes',
                name: 'HomePage',
                component: () => import('@/views/HomeView.vue')
            },

           
            {
                path: '/contactus',
                name: 'ContactUs',
                component: () => import('@/views/ContactUs.vue')
            },
            {
                path: '/contactus-1',
                name: 'ContactUs-1',
                component: () => import('@/views/ContactUs-1.vue')
            },
            {
                path: 'Location',
                name: 'Location',
                component: () => import('@/views/ContactUs.vue')
            },
            {
                path: '/Package',
                name: 'Package',
                component: () => import('@/views/PackageView.vue')
            },
            {
                path: '/blog',
                name: 'Blog',
                component: () => import('@/views/Blog/Blog.vue')
            },
            {
                path: '/blogdetail',
                name: 'Blog Detail',
                component: () => import('@/views/Blog/blogdetail.vue')
            },
            {
                path: '/pricing',
                name: 'pricing',
                component: () => import('@/views/Pricing/pacakgelist.vue')
            },
            {
                path: '/checkout/:subscriptionId',
                name: 'checkout',
                component: () => import('@/views/Pricing/checkoutPackage.vue')
            },
            {
                path: '/thankyou',
                name: 'thankyou',
                component: () => import('@/views/Thankyou.vue')
            },
            {
                path: '/Aboutus',
                name: 'Aboutus',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Feed',
                name: 'Feed',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/AnimalCare',
                name: 'AnimalCare',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Hay',
                name: 'Hay',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Gourmetfoods',
                name: 'Gourmetfoods',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Equipment',
                name: 'Equipment',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Howitworks',
                name: 'Howitworks',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/FAQ',
                name: 'FAQ',
                component: () => import('@/views/Faqs.vue')
            },
            {
                path: '/Farm&Ranch',
                name: 'Farm&Ranch',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/PrivacyPolicy',
                name: 'PrivacyPolicy',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/WhoWeAre',
                name: 'WhoWeAre',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/InvestorRelations',
                name: 'InvestorRelations',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Community',
                name: 'Community',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/ProductCatalog',
                name: 'ProductCatalog',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Careers',
                name: 'Careers',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/AffiliateProgram',
                name: 'AffiliateProgram',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/VendorInformation',
                name: 'VendorInformation',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Sponsorship',
                name: 'Sponsorship',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/PressRelease',
                name: 'PressRelease',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/requestdemo',
                name: 'requestdemo',
                component: () => import('@/views/RequestDemo.vue')
            },
            {
                path: '/testimonailList',
                name: 'testimonailList',
                component: () => import('@/views/Testimonial/testimonialList.vue'),
            },
            {
                path: '/Features',
                name: 'Features',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Resources',
                name: 'Resources',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Careers',
                name: 'Careers',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Accounting',
                name: 'Accounting',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Communications',
                name: 'Communications',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Marketing',
                name: 'Marketing',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Leasing&Applications',
                name: 'Leasing&Applications',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/TenantPortal',
                name: 'TenantPortal',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/PressNews',
                name: 'PressNews',
                component: () => import('@/views/News/news.vue')
            },
            {
                path: '/pressnewsdetail/:id',
                name: 'Press Detail',
                component: () => import('@/views/News/newsdetail.vue')
            },
            {
                path: '/TermsandConditions',
                name: 'Terms and Conditions',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/unsubscribenewsletter/:email',
                name: 'Unsubscribe Newsletter',
                component: () => import('@/views/shared/UnsubscribeNewsletter/unsubscribenewsletter.vue')
            },
            {
                path: '/ErrorPage',
                name: 'Page Not Found',
                component: () => import('@/views/shared/errorPage.vue')
            },
            {
                path: '/ProductDetail/:Id',
                name: 'ProductDetails',
                component: () => import('@/views/Shop/ProductDetails.vue')
            },
            {
                path: '/Subscription',
                name: 'Susbcription',
                component: () => import('@/views/Shop/Subscription.vue')
            },
            {
                path: '/Cart/:Id',
                name: 'Cart',
                component: () => import('@/views/Shop/Cart.vue')
            },
            {
                path: 'Cart',
                name: 'Carts',
                component: () => import('@/views/Shop/Cart.vue')
            },
            {
                path: '/Checkout',
                name: 'Checkout',
                component: () => import('@/views/Shop/Checkout.vue')
            },
            
            {
                path: '/Order',
                name: 'Order',
                component: () => import('@/views/Shop/Order.vue')
            },
            {
                path: '/Products/:Id',
                name: 'Products',
                component: () => import('@/views/Shop/Products.vue')
            },
            {
                path: '/ProductList',
                name: 'ProductList',
                component: () => import('@/views/Shop/Products.vue')
            },
            {
                path: 'Onsale',
                name: 'Onsale',
                component: () => import('@/views/Shop/Products.vue')
            },

            {
                path: '/WhoWeAre',
                name: 'Who We Are',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/Login',
                name: 'Login',
                component: () => import('@/views/Login/LoginPage.vue')
            },
            {
                path: '/SignUp',
                name: 'SignUp',
                component: () => import('@/views/SignUp/SignUp.vue')
            },
            {
                path: '/UserprofilePage',
                name: 'UserprofilePage',
                component: () => import('@/views/UserProfile/UserprofilePage.vue')
            },
            {
                path: '/Subscriptions',
                name: 'Subscriptions',
                component: () => import('@/views/UserProfile/Subscriptions.vue')
            },
            {
                path: '/TrackingOrder',
                name: 'TrackingOrders',
                component: () => import('@/views/UserProfile/TrackingOrder.vue')
            },
            {
                path: '/AppointMents',
                name: 'AppointMents',
                component: () => import('@/views/UserProfile/AppointMents.vue')
            },
            {
                path: '/Learning',
                name: 'Learnings',
                component: () => import('@/views/UserProfile/Learning.vue')
            },
            {
                path: '/Notifications',
                name: 'Notifications',
                component: () => import('@/views/UserProfile/Notifications.vue')
            },
            {
                path: '/Calender',
                name: 'Calender',
                component: () => import('@/views/UserProfile/Calender.vue')
            },
            {
                path: '/Orders',
                name: 'Oders',
                component: () => import('@/views/UserProfile/Oders.vue')
            },
            {
                path: '/MapView',
                name: 'MapView',
                component: () => import('@/views/UserProfile/MapView.vue')
            },
            {
                path: '/ResetPassword',
                name: 'ResetPassword',
                component: () => import('@/views/UserProfile/ResetPassword.vue')
            },
            {
                path: '/account/resetpassword',
                name: 'Resetpasswordmodel',
                component: () => import('@/views/Resetpasswordmodel/Resetpasswordmodel.vue')
            },
            {
                path: '/ForgotPassword',
                name: 'ForgotPassword',
                component: () => import('@/views/UserProfile/ForgotPassword.vue')
            },
            {
                path: '/AddBillingAddress',
                name: 'AddBillingAddress',
                component: () => import('@/views/UserProfile/AddBillingAddress.vue')
            },
            {
                path: '/AddShippingAddress',
                name: 'AddShippingAddress',
                component: () => import('@/views/UserProfile/AddShippingAddress.vue')
            },
            {
                path: '/CheckoutShippingDetails',
                name: 'CheckoutShippingDetails',
                component: () => import('@/views/Shop/CheckoutShippingDetails.vue')
            },
            {
                path: '/OrderConfirmation',
                name: 'OrderConfirmation',
                component: () => import('@/views/Shop/OrderConfirmation.vue')
            },
            {
                path: '/Confirmation',
                name: 'Confirmation',
                component: () => import('@/views/Shop/Confirmation.vue')
            },
            
            {
                path: '/_Checkout',
                name: 'CheckoutStatic',
                component: () => import('@/views/DesignPages/Checkout.vue')
            },



            {
                path: '/DeleteAccount',
                name: 'DeleteAccount',
                component: () => import('@/views/UserProfile/DeleteAccount.vue')
            },


            
            //{
            //    path: '/OrderTracking',
            //    name: 'OrderTracking',
            //    component: () => import('@/views/DesignPages/OrderTracking.vue')
            //},
            //{
            //    path: '/_MyAccountSubscription',
            //    name: '_MyAccountSubscription',
            //    component: () => import('@/views/DesignPages/MyAccountSubscription.vue')
            //},
            //{
            //    path: '/_MyAccountCalender',
            //    name: '_MyAccountCalender',
            //    component: () => import('@/views/DesignPages/MyAccountCalender.vue')
            //},
            //{
            //    path: '/_MyAccountAppointment',
            //    name: '_MyAccountAppointment',
            //    component: () => import('@/views/DesignPages/MyAccountAppointment.vue')
            //},
            //{
            //    path: '/_MyAccountOrderTracking',
            //    name: '_MyAccountOrderTracking',
            //    component: () => import('@/views/DesignPages/MyAccountOrderTracking.vue')
            //},
            //{
            //    path: '/_MyAccountLearning',
            //    name: '_MyAccountLearning',
            //    component: () => import('@/views/DesignPages/MyAccountLearning.vue')
            //},
            //{
            //    path: '/_MyAccountMapView',
            //    name: '_MyAccountMapView',
            //    component: () => import('@/views/DesignPages/MyAccountMapView.vue')
            //},
            //{
            //    path: '/_MyAccountDashboard',
            //    name: '_MyAccountDashboard',
            //    component: () => import('@/views/DesignPages/MyAccountDashboard.vue')
            //},
            //{
            //    path: '/_MyAccountChangePassword',
            //    name: '_MyAccountChangePassword',
            //    component: () => import('@/views/DesignPages/MyAccountChangePassword.vue')
            //},
            //{
            //    path: '/_MyAccountBillingAddress',
            //    name: '_MyAccountBillingAddress',
            //    component: () => import('@/views/DesignPages/MyAccountBillingAddress.vue')
            //},
            //{
            //    path: '/_MyAccountShippingAddress',
            //    name: '_MyAccountShippingAddress',
            //    component: () => import('@/views/DesignPages/MyAccountShippingAddress.vue')
            //},
            //{
            //    path: '/_MyAccountNotification',
            //    name: '_MyAccountNotification',
            //    component: () => import('@/views/DesignPages/MyAccountNotification.vue')
            //},

            /*---Footers---*/
            {
                path: '/TrackYourOrder',
                name: 'TrackYourOrder',
                component: () => import('@/views/StaticPages/TrackYourOrder.vue')
            },
            {
                path: '/ReturnPolicy',
                name: 'ReturnPolicy',
                component: () => import('@/views/StaticPages/ReturnPolicy.vue')
            },
            {
                path: '/DeliveryOptions',
                name: 'DeliveryOptions',
                component: () => import('@/views/StaticPages/DeliveryOptions.vue')
            },
            {
                path: '/TaxExemptions',
                name: 'TaxExemptions',
                component: () => import('@/views/StaticPages/TaxExemptions.vue')
            },
            {
                path: '/Sell',
                name: 'Sell',
                component: () => import('@/views/StaticPages/SellYourHome.vue')
            },
            {
                path: '/Scheduling',
                name: 'Scheduling',
                component: () => import('@/views/StaticPages/Scheduling.vue')
            },
            {
                path: '/TermsandConditions',
                name: 'TermsandConditions',
                component: () => import('@/views/StaticPages/TermsandConditions.vue')
            },
            //{
            //    path: '/pressnews',
            //    name: 'pressnews',
            //    component: () => import('@/views/StaticPages/PressRelease.vue')
            //},
            {
                path: '/VeteransVacation',
                name: 'VeteransVacation',
                component: () => import('@/views/StaticPages/VeteransVacation.vue')
            },
            {
                path: '/TSCCreditCardOffersFinancingOptions',
                name: 'TSCCreditCardOffersFinancingOptions',
                component: () => import('@/views/StaticPages/TSCCreditCardOffersFinancingOptions.vue')
            },
            {
                path: '/orderdetail/:Id',
                name: 'orderDetail',
                component: () =>
                    import('@/views/UserProfile/orderDetail.vue'),
            },
            {
                path: '/AnimalHealthSupplies',
                name: 'AnimalHealthSupplies',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/CatFoodAndSupplies',
                name: 'CatFoodAndSupplies',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: '/DogFoodAndSupplies',
                name: 'DogFoodAndSupplies',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },
            {
                path: ':url',
                name: 'dynamicPage',
                component: () => import('@/views/dynamicPages/dynamicPages.vue')
            },

        ]
    },
    {
        path: '/logoutRedirect/:origin',
        name: 'logoutRedirect',
        component: () => import('../Layout/LogoutRedirect.vue')
    },
    //{
    //    path: '/errorPage',
    //    name: 'errorPage',
    //    component: () => import('@/views/ErrorPage/errorPage.vue')
    //}
];



//Global Arrays For Dynamic Menu
let HeaderNavlinks = []
let FooterNavlinks1 = []
let FooterNavlinks2 = []
let FooterNavlinks3 = []
let HeaderTopNavlinks = []
let FooterAboutusNavLinks = []
let FooterworkusNavLinks = []
const router = createRouter({
    //history: createWebHistory(),

    history: createWebHistory(process.env.BASE_URL),
    routes, HeaderNavlinks, FooterNavlinks1, FooterNavlinks2, FooterNavlinks3, HeaderTopNavlinks, FooterAboutusNavLinks, FooterworkusNavLinks
})


function createMenuItems(menuList) {
    return menuList.map(item => {
        //debugger
        const matchingRoute = routes[0].children.find(route => route.name === item.routeUrl);
        return {
            /*routeUrl: matchingRoute ? item.routeUrl : 'Home',*/
            routeUrl: matchingRoute ? item.routeUrl : 'dynamicPage',
            displayText: item.displayText,
            childMenu: item.ChildMenu,
            params: matchingRoute ? null : { url: item.routeUrl },
        };
    });
}

async function setMenuItems(userId, companyId) {
    const response = await frontEndService.GetHeaderFooterData(userId, companyId);
    const HeaderFooterList = JSON.parse(Object.values(response.data));
    HeaderNavlinks.push(JSON.stringify(createMenuItems(HeaderFooterList.HeaderMenuListing)));
    FooterNavlinks1.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing1)));
    FooterNavlinks2.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing2)));
    FooterNavlinks3.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuListing3)));
    //HeaderTopNavlinks.push(JSON.stringify(createMenuItems(HeaderFooterList.HeaderTopMenuListing)));
    //FooterAboutusNavLinks.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuAboutusListing)));
    FooterworkusNavLinks.push(JSON.stringify(createMenuItems(HeaderFooterList.FooterMenuworkusListing)));
}

async function GetFooterDetails() {
   // debugger;
    await AuthUserServices.GetDynamicContentForComapny('00000000-0000-0000-0000-000000000000').then(res => {
        localStorage.removeItem('companyphone');
        localStorage.removeItem('companyemail');
        localStorage.removeItem('companycontent');
        localStorage.removeItem('companySecondaryLogo');
        localStorage.removeItem('companyFavicon');
        localStorage.removeItem('companyId');
        localStorage.setItem('companyphone', res.data.phone);
        localStorage.setItem('companyemail', res.data.email);
        localStorage.setItem('companycontent', res.data.footerContent);
        localStorage.setItem('companySecondaryLogo', res.data.secondaryLogoLink);
        localStorage.setItem('companyFavicon', res.data.favIconLink);
        localStorage.setItem('companyId', '00000000-0000-0000-0000-000000000000');
    })

}

router.beforeEach(async (to, from, next) => {
    const specialRoutes = ['errorPage', 'logoutRedirect'];
    if (specialRoutes.includes(to.name)) {
        return next();
    }
    let companyDomainName = 'AppleInc'; //location.host;

    let companyUniqueName = to.path.split('/')[1] || to.path.substring(1);
    await GetFooterDetails();
    try {
        //if (helpers.IsNull(companyDomainName, true, null) == null) {
        //    throw new Error('Invalid company data');
        //}
        if (to.matched.length == 0) {
            throw new Error('Invalid Page');
        }
        //if (companyDomainName == 'AppleInc' || companyDomainName == 'AppleInc.com') {
        //    window.location.href = 'https://www.beenee.com';
        //}

      /*  const companyDetailsResponse = await frontEndService.GetCompanyDetailsByDomainOrName(companyDomainName, companyUniqueName);*/

        //if (companyDetailsResponse.data.statusCode !== 200) {
        //    throw new Error('Company details not found');
        //}

        //const companyData = companyDetailsResponse.data.result;
        //await SetData(companyData, to.name, companyUniqueName);

        await setMenuItems('8D8ED18C-17A4-46F1-BC9B-F2607162A06C', '00000000-0000-0000-0000-000000000000');
     /* this.GetFooterDetails()*/
        //await beforeRouteHitMethods(to);
        //if (to.hash == '' || to.hash == null) {
        //    window.scrollTo(0, 0);
        //}
        //await GetmetaData(to);
        return next();

    }
    catch (error) {
        console.error(error);
        return next('/errorPage');
    }
})

async function SetData(companyData, title, companyUniqueName) {
 //  debugger;
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('comp-userId');
    localStorage.removeItem('companyURL');
    localStorage.removeItem('companyLogo');
    localStorage.removeItem('theme');
    localStorage.removeItem('companyAddress');
    localStorage.removeItem('showAssetViewIcon');
    localStorage.removeItem('cartaction');

    localStorage.setItem('companyId', companyData.companyId);
    localStorage.setItem('companyName', companyData.companyName);
    localStorage.setItem('comp-userId', companyData.userId);
    localStorage.setItem('companyURL', companyUniqueName);
    localStorage.setItem('companyLogo', companyData.companyPrimaryLogo ? companyData.companyPrimaryLogo : companyData.companySecondaryLogo);
    localStorage.setItem('companySecondaryLogo', companyData.companySecondaryLogo ? companyData.companySecondaryLogo : companyData.companyPrimaryLogo);
    localStorage.setItem('theme', companyData.theme); //companyData.theme'
    localStorage.setItem('companyAddress', companyData.companyAddress);
    localStorage.setItem('showAssetViewIcon', companyData.assetViews);
    localStorage.setItem('cartaction','dontcalc');

    //Check for login in user belong to current company or not 
    if (localStorage.getItem('userinfo') != null && localStorage.getItem('userinfo') != undefined) {
        let userDataJSON = JSON.parse(localStorage.getItem('userinfo'));
        let companyId = userDataJSON.data ? userDataJSON.data.companyId : userDataJSON.companyId;
        if (companyId != localStorage.getItem('companyId').toLowerCase()) {
            localStorage.removeItem("comp-userId");
        }
    }

    if (title == 'undefinded' || typeof title == "undefined") {
        title = ''
    }
    document.title = `${companyData.companyName} | ` + `${title}`;
    return true


}


// this fuction will check for side compare bar close fucntionality 
async function beforeRouteHitMethods(to) {
    var compareElement = document.getElementById('comparisonblock');
    if (to.name.indexOf('HomePage') == 0 || to.name.indexOf('Listings') == 0) {
    }
    else {
        if (compareElement != null) {
            compareElement.classList.remove('open');
        }
    }
    return
}

async function GetmetaData(to) {
    let metaModel = {
        PageTitle: to.name,
        PageUrl: to.href.replace('/', ''),
        companyId: localStorage.getItem('companyId')
    }
    const response = await frontEndService.GetdynamicMetadata(metaModel);
    document.getElementById('keyword').setAttribute("content", response.data.MetaKeyword)
    document.getElementById('description').setAttribute("content", response.data.MetaDescription)
    document.getElementById('title').setAttribute("content", response.data.MetaAuthor)
    var screen = window.outerWidth;
    if (screen > 1080) {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1")
    } else {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1, user-scalable='no'")
    }
}

export default router
