/// <reference path="helpers/filters.js" />

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
/*import "bootstrap"*/
import "primevue/resources/themes/vela-green/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons 
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import ToastService from 'primevue/toastservice';
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import Button from "primevue/button";
import FilterUtility from '@/Helpers/filters.js'
import HelperFunction from '@/Helpers/Helpers.js';
import { LoadingPlugin } from 'vue-loading-overlay';
import * as ConfirmDialog from "vuejs-confirm-dialog";
import bootstrap from '@/assets/css/bootstrap.min.css';
import '@/assets/css/style.css';
import '@/assets/css/imageurl.style.css';
import bootstrapJs from '@/assets/js/bootstrap.min.js';
import mainjqueryJs from '@/assets/js/jquery-3.7.1.min.js';

import Loading from '@/Layout/Loader.vue';
const app = createApp(App).use(router)

window.$ = window.jQuery = require('jquery');

app.use(PrimeVue)
app.use(LoadingPlugin);
app.use(ToastService);
app.use(ConfirmDialog);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Paginator', Paginator);
app.component("Toast", Toast);
app.component("Button", Button);
app.component('Loading', Loading);

//golbal 
app.config.globalProperties.$filters = FilterUtility;
app.config.globalProperties.$Helpers = HelperFunction;
//app.config.globalProperties.$filters = HelperFunction;

//Services
app.provide('axios', axios);

app.mixin("", bootstrap);
app.mixin("", bootstrapJs);
app.mixin("", mainjqueryJs);
app.mixin({
    methods: {
        success: function (msg) {
            this.$toast.add({ severity: 'success', summary: "Success Message", detail: msg, life: 3000 });
        },
        error: function (msg) {
            this.$toast.add({
                severity: 'error', summary: "Error Message", detail: msg, life: 3000
            });
        },
        warn: function (msg) {
            this.$toast.add({
                severity: 'warn', summary: "Warning Message", detail: msg, life: 3000
            });
        }
    },
})



app.use(function (req, res, next) {
    const isProduction = process.env.VUE_APP_PRODUCTION;
    if (isProduction.toLocaleLowerCase() == "true") {
        if (location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
        }
    }
});
app.mount('#app');
